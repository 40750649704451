<template>
  <div class="document-library-wrapper">
    <div class="mb-32 component__header">
      <h1>कागजात संग्रह</h1>
    </div>
    <div class="container container__document-library">
      <div class="flex filter-service" @click="toggleFilter">
        <span>कागजात फिल्टर गर्नुहाेस् </span>
        <i>Service</i>
      </div>
      <DocumentLibraryStats :stats="stats"></DocumentLibraryStats>
      <i
        class="close-filter"
        @click="closeFilter"
        :class="showFilter ? 'show' : 'hide'"
      ></i>
      <div class="flex document-library__content">
        <div class="filter-sidebar" :id="showFilter ? 'show' : 'hidden'">
          <div class="flex filter-title">
            <h4>कागजात फिल्टर</h4>
            <button
              type="button"
              class="btn btn-ghost btn-reset"
              @click.stop="resetFilter"
            >
              रिसेट
            </button>
          </div>
          <vue-collapsible-panel-group accordion>
            <vue-collapsible-panel>
              <!-- document types -->
              <template #title>
                <span>कागजातको प्रकार</span>
              </template>
              <template #content>
                <div class="filters">
                  <label
                    v-for="(ele, index) in documentTypes"
                    :key="index"
                    class="checkbox-wrapper"
                  >
                    <input
                      type="checkbox"
                      :value="ele.type_id"
                      v-model="params.document_type"
                    />
                    <span class="checkmark"></span>
                    {{ ele.title_ne }}
                  </label>
                </div>
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <!-- document source -->
              <template #title> कागजातकाे स्राेत </template>
              <template #content>
                <div class="filters">
                  <label
                    v-for="(ele, index) in documentSources"
                    :key="index"
                    class="checkbox-wrapper"
                  >
                    <input
                      type="checkbox"
                      :value="ele.source_id"
                      v-model="params.document_source"
                    />
                    <span class="checkmark"></span>
                    {{ ele.title_ne }}
                  </label>
                </div>
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <!-- category and subcategory -->
              <template #title> वर्ग </template>
              <template #content>
                <div
                  class="category-filters"
                  v-for="(ele, index) in documentCategories"
                  :key="index"
                >
                  <!-- main category -->
                  <div class="header">
                    <label class="checkbox-wrapper">
                      <input
                        type="checkbox"
                        class="parent-input"
                        :value="ele.category_id"
                        v-model="params.category_id"
                        @click="
                          checkChildren(
                            ele.category_id,
                            ele.subcategories,
                            $event
                          )
                        "
                      />
                      <span class="checkmark"> </span>{{ ele.title_ne }}
                    </label>
                  </div>
                  <!-- subcategories -->
                  <div class="sub-filters">
                    <label
                      v-for="(subele, i) in ele.subcategories"
                      :key="i"
                      class="checkbox-wrapper"
                    >
                      <input
                        type="checkbox"
                        class="child-input"
                        :value="subele.category_id"
                        v-model="params.subcategory_id"
                      />
                      <span class="checkmark"></span>
                      {{ subele.title_ne }}
                    </label>
                  </div>
                </div>
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <!-- published year -->
              <template #title> प्रकाशित मिति </template>
              <template #content>
                <div class="filters">
                  <label
                    v-for="(ele, index) in publishedYears"
                    :key="index"
                    class="checkbox-wrapper"
                  >
                    <input
                      type="checkbox"
                      :value="ele"
                      v-model="params.published_year"
                    />
                    <span class="checkmark"></span>
                    {{ englishToNepaliNumber(ele) }}
                  </label>
                </div>
              </template>
            </vue-collapsible-panel>
          </vue-collapsible-panel-group>
          <button
            class="btn btn--gradient filter-button"
            type="button"
            @click.stop="fetchDocuments"
          >
            फिल्टर गर्नुहाेस्
          </button>
        </div>
        <div class="datatable">
          <div class="flex datatable-heading">
            <h3 class="datatable-header"><span>कागजात</span></h3>
            <div class="flex datatable-filters">
              <div class="search-document">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="6.84442"
                    cy="6.84394"
                    rx="5.99237"
                    ry="5.99237"
                    stroke="url(#paint0_linear_2463_7483)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.0122 11.3223L13.3616 13.6655"
                    stroke="url(#paint1_linear_2463_7483)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2463_7483"
                      x1="0.852051"
                      y1="0.851563"
                      x2="12.8368"
                      y2="12.8363"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#F97794" />
                      <stop offset="1" stop-color="#623AA2" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2463_7483"
                      x1="11.0122"
                      y1="11.3223"
                      x2="13.3554"
                      y2="13.6716"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#F97794" />
                      <stop offset="1" stop-color="#623AA2" />
                    </linearGradient>
                  </defs>
                </svg>
                <input
                  type="text"
                  placeholder="शीर्षकबाट कागजात खाेज्नुहाेस्"
                  v-model="params.title"
                />
                <button
                  type="button"
                  class="search-button"
                  :disabled="params.title.length == 0"
                  @click.stop="fetchDocuments"
                >
                  खाेज्नुहाेस्
                </button>
              </div>
              <div class="filter-document">
                <label for="orderby">क्रमबद्ध गर्नुहोस्</label>
                <!-- <select
                  name="orderby"
                  id="orderby"
                  class="datatable-dropdown"
                  @change="changeOrder"
                >
                  <option
                    v-for="(ele, i) in selectOptions"
                    :key="i"
                    :value="ele.value"
                  >
                    {{ ele.label }}
                  </option>
                </select> -->
                <Multiselect
                  v-model="value"
                  :options="selectOptions"
                  label="label"
                  :canClear="false"
                  :canDeSelect="false"
                />
              </div>
            </div>
          </div>
          <DocumentLibraryTable
            v-if="documents.length"
            :doclist="documents"
            :reset="reset"
            :order="this.params.order_by"
            :sort="this.params.sort_by"
            @sortEvent="sortEvent"
          ></DocumentLibraryTable>
          <NoDataFound v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import DocumentLibraryRepository from "./../../repositories/DocumentationHubRepository";
import { englishToNepaliNumber } from "nepali-number";
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";
import Multiselect from "@vueform/multiselect";
const DocumentLibraryTable = defineAsyncComponent(() =>
  import("./DocumentLibraryTable.vue")
);
const DocumentLibraryStats = defineAsyncComponent(() =>
  import("./DocumentLibraryStats.vue")
);
const NoDataFound = defineAsyncComponent(() =>
  import("../../components/NoDataFound.vue")
);

export default {
  components: {
    DocumentLibraryTable,
    DocumentLibraryStats,
    VueCollapsiblePanel,
    VueCollapsiblePanelGroup,
    Multiselect,
    NoDataFound,
  },
  data() {
    return {
      englishToNepaliNumber,
      params: {
        mpc_id: process.env.VUE_APP_LOCAL_GOV_ID,
        sort_by: "created_at",
        order_by: "desc",
        title: "",
        category_id: [],
        subcategory_id: [],
        document_type: [],
        document_source: [],
        published_year: [],
      },
      documentSources: [],
      documentTypes: [],
      publishedYears: [],
      documentCategories: [],
      documents: [],
      reset: false,
      stats: [],
      selectOptions: [
        {
          label: "हालसालै थपिएकाे",
          value: "desc",
        },
        {
          label: "पुरानाे पहिला",
          value: "asc",
        },
      ],
      value: "desc",
      default: {
        placeholder: "multiselect-placeholder",
      },
      showFilter: false,
    };
  },
  async mounted() {
    try {
      let categories = await DocumentLibraryRepository.getCategories();
      this.documentTypes = categories.data.data.document_types;
      this.documentSources = categories.data.data.document_source;
      this.publishedYears = categories.data.data.published_year;
      this.documentCategories = categories.data.data.categories;
      this.fetchDocuments();
    } catch (err) {
      console.error(err);
    }

    this.$store.dispatch("setLoading", false);
  },
  methods: {
    checkChildren(category_id, children, event) {
      if (event.target.checked) {
        //adds to category and subcategory
        if (this.params.category_id.indexOf(category_id) == -1) {
          this.params.category_id.push(category_id);
        }

        for (const i in children) {
          if (
            this.params.subcategory_id.indexOf(children[i].category_id) == -1
          ) {
            this.params.subcategory_id.push(children[i].category_id);
          }
        }
      } else {
        //removes from category and subcategory
        let catindex = this.params.category_id.indexOf(category_id);

        if (catindex != -1) {
          this.params.category_id.splice(catindex, 1);
        }

        for (const i in children) {
          let subcatindex = this.params.subcategory_id.indexOf(
            children[i].category_id
          );
          if (subcatindex != -1) {
            this.params.subcategory_id.splice(subcatindex, 1);
          }
        }
      }
    },
    async fetchDocuments() {
      this.$store.dispatch("setDatatableLoadingState", true);
      try {
        let query = this.prepareParams();
        let { data } =
          await DocumentLibraryRepository.getDocumentationHubFiltered(query);
        this.documents = data.data;
        this.stats = data.metadata.document_type;
        this.reset = !this.reset;
      } catch (error) {
        console.log(error);
      }
      this.$store.dispatch("setDatatableLoadingState", false);
    },
    prepareParams() {
      let multiple = [
        "category_id",
        "subcategory_id",
        "document_type",
        "document_source",
      ];
      let query = "?";

      for (const i in this.params) {
        if (this.params[i]) {
          if (multiple.includes(i)) {
            let joint = this.params[i].join(",");
            query += joint ? `${i}=${joint}&` : "";
            continue;
          }

          query += `${i}=${this.params[i]}&`;
        }
      }

      return query;
    },
    resetFilter() {
      this.params = {
        mpc_id: process.env.VUE_APP_LOCAL_GOV_ID,
        sort_by: "created_at",
        order_by: "desc",
        title: "",
        category_id: [],
        subcategory_id: [],
        document_type: [],
        document_source: [],
        published_year: [],
      };
      this.value = "desc";

      this.fetchDocuments();
    },
    changeOrder() {
      this.params.sort_by = "created_at";

      this.fetchDocuments();
    },
    sortEvent(event) {
      this.params.sort_by = event.sort;
      this.params.order_by = event.order;

      this.fetchDocuments();
    },
    toggleFilter: function () {
      this.showFilter = !this.showFilter;
    },
    closeFilter: function () {
      this.showFilter = false;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.params.sort_by = "created_at";
        this.params.order_by = val;

        this.fetchDocuments();
      },
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.document-library {
  &-wrapper {
    padding-bottom: 80px;

    .container {
      max-width: 1200px;
    }
  }
  &__content {
    align-items: flex-start;
  }
}

.datatable {
  background: $neutrals-white;
  border-radius: 20px;
  width: calc(100% - 260px);
  &-heading {
    border-bottom: 1px solid #c4c4c4;
    justify-content: space-between;
    padding: 24px 32px;
    @media all and (min-width: 900px) and (max-width: 1060px),
      all and (max-width: 768px) {
      display: block;
      h3 {
        margin-bottom: 16px;
      }
    }
  }
  &-filters {
    align-items: center;
    @media all and (max-width: 768px) {
      justify-content: space-between;
    }
    @media all and (max-width: 580px) {
      display: block;
    }
  }
  @media all and (max-width: 900px) {
    width: 100%;
  }
}

.filter-service {
  justify-content: flex-end;
  margin-bottom: 20px;
  span {
    align-items: center;
    color: $primary;
    display: flex;
    font-family: "Mukta-Bold";
    margin-right: 10px;
  }
}

.filter-sidebar {
  margin-right: 24px;
  overflow: auto;
  width: 300px;
  &#show {
    display: block;
  }
}

.filters-type {
  border-bottom: 1px solid #c4c4c4;
  padding: 16px 24px;
  .header {
    font-size: 18px;
    font-family: "Mukta-Medium";
    margin-bottom: 20px;
  }
}

.checkbox-wrapper {
  /* font-size: 14px; */
  font-family: "Mukta-Medium";
}

.category-filters {
  margin-bottom: 24px;
  .header {
    margin-bottom: 10px;
    .checkbox-wrapper {
      font-size: 16px;
    }
  }
}

.sub-filters {
  margin-left: 32px;
}

.filter-button {
  margin: 12px 24px;
  width: calc(100% - 48px);
}

.search-document {
  border: 1px solid #b95d9a;
  border-radius: 32px;
  margin-right: 40px;
  width: 300px;
  @media all and (max-width: 1200px) {
    margin-right: 20px;
  }
  @media all and (max-width: 800px) {
    width: 290px;
  }
  @media all and (max-width: 580px) {
    margin-bottom: 20px;
    width: 100%;
  }
  svg {
    float: left;
    margin-left: 16px;
    margin-top: 8px;
  }
  input[type="text"] {
    background-color: transparent;
    border: 0;
    font-size: 14px;
    padding: 0 16px 0 10px;
    width: calc(100% - 124px);
  }
}

.search-button {
  @include bg-primary-gradient;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  color: $neutrals-white;
  font-size: 14px;
  height: 36px;
  width: 93px;
}

.filter-document {
  align-items: center;
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  label {
    margin-right: 16px;
    @media all and (max-width: 700px) {
      display: none;
    }
  }
  .multiselect {
    background: transparent !important;
    border: 1px solid #b95d9a !important;
    border-radius: 100px !important;
    color: $primary !important;
    height: 32px;
    width: 150px;
    @media all and (max-width: 580px) {
      width: 100% !important;
    }
  }
}

.container__document-library {
  padding: 0 20px;
}
</style>
