import axiosClient from "./clients/axiosClient";
const resource = "/documentation-hub";
// const lgId = process.env.VUE_APP_LOCAL_GOV_ID;

export default {
  getCategories(params = "") {
    return axiosClient.get(`${resource}-categories${params}`);
  },
  getDocumentationHub(params = "") {
    return axiosClient.get(`${resource}/${params}`);
  },
  getDocumentationHubFiltered(params = "") {
    return axiosClient.get(`${resource}/filter${params}`);
  },
  getDocumentationHubDetail(id) {
    return axiosClient.get(`${resource}/${id}`);
  },
  getDocumentationHubRelated(id) {
    return axiosClient.get(`${resource}/${id}/related`);
  },
  getDocumentationHubDownload(id) {
    return axiosClient.get(`${resource}/${id}/download-all`);
  },
};
